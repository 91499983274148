<template>
    <div v-if="mediaItem">
        <div class="uk-text-left">
            <h2 class="uk-text-left"><a @click="$router.go(-1)" style="text-decoration: none;" class="uk-flex-inline media-header-text"><span uk-icon="icon: chevron-left; ratio: 1.7"></span>
                <span class="uk-width-2xlarge uk-text-truncate">{{mediaItem.title || $t('Generic.loading')}}</span></a>
            </h2>
        </div>
        <div class="uk-container uk-width-1-1 uk-card-default uk-card-large uk-margin-remove-left">
            <div class="uk-card-body uk-padding-remove uk-margin-small-top uk-text-left">
                <img class="uk-margin-medium-bottom" v-if="isImage" :src="url" alt="Media Item"  />
                <IconicPlayer v-if="isVideo" class="uk-margin-medium-bottom uk-text-left uk-flex iconic-player" :media="mediaItem" :orgId="orgId"/>
                <div v-if="url" class="uk-margin-medium-bottom uk-flex">
                    <div class="uk-width-expand">
                        <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.SourceURL') }} </label>
                        <div class="uk-text-truncate" v-if="!edit || isMediaFileInternal(mediaItem)">
                            <a  :href="url"> {{ url }} </a>
                        </div>
                        <div v-else-if="edit && !isMediaFileInternal(mediaItem)">
                            <input class="uk-input uk-margin-small-top" type="text" placeholder="Enter URL" v-model="externalURL" />
                            <p class="uk-text-left uk-text-danger uk-margin-remove-bottom uk-margin-remove-top" v-if="!validateUrl"> {{ $t('Pages.MediaLibrary.InvalidURL') }} </p>
                        </div>
                        <div v-if="!edit || isMediaFileInternal(mediaItem)">
                            <button style="padding-left:20px; padding-right:20px" class="uk-button uk-button-primary uk-margin-small-top" type="button"  @click="copy"> {{ copied ? 'Copied' : 'Copy' }} {{ $t('Pages.MediaLibrary.Labels.ToClipboard') }} </button>
                        </div>
                    </div>
                    <div class="uk-align-right">
                        <span v-if="!edit" uk-icon="icon: pencil" style="cursor: pointer;"  @click="clicked"> </span>
                    </div>
                </div>
                <div class="uk-width-1-1 uk-flex">
                    <div class="uk-width-1-3 uk-margin-right">
                        <div class="uk-margin-medium-bottom">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.Title') }} 
                                <span class="uk-text-danger">*</span>
                            </label>
                            <div v-if="!edit" class="uk-text-break">
                                <span class="uk-text-bold uk-text-left"> {{ mediaItem.title }} </span>
                            </div>
                            <div v-else>
                                <div class="uk-inline uk-width-1-1">
                                    <input :class="{ 'invalid-border': !invalidName }" class="uk-input" type="text" :placeholder="$t('Pages.MediaLibrary.Placeholders.Title')" v-model.trim="title" />
                                    <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!invalidName" uk-icon="icon: warning" ></span>
                                    <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!invalidName" >{{$t('Pages.MediaLibrary.inValidTitleError') }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-margin-medium-bottom">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.Description') }} </label>
                            <div v-if="!edit" class="uk-text-break">
                                <span class="uk-text-bold uk-text-left"> {{ mediaItem.description ||  'None' }}   </span>
                            </div>
                            <div v-else>
                                <textarea style="min-height: 80px" class="uk-input uk-margin-small-top" :placeholder="$t('Pages.MediaLibrary.Placeholders.Description')" v-model.trim="description"/>
                            </div>
                        </div>
                        <div v-if="!edit" class="uk-margin-medium-bottom">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.Size') }} </label>
                            <div>
                                <span class="uk-text-bold uk-text-left"> {{ getMediaSizeBytes( mediaItem ) | appropriateBytes }}   </span>
                            </div>
                        </div>
                        <div v-if="isVideo && isTranscodeCompleted(mediaItem) && !edit" class="uk-margin-medium-bottom">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.TranscodeType') }} </label>
                            <div>
                                <span class="uk-text-bold uk-text-left"> {{ $t( `transcodeFormat.${mediaItem.transcodeType}` ) }}   </span>
                            </div>
                        </div>
                    </div>
                    <div v-if="!edit" class="uk-width-1-3 uk-margin-right">
                        <div v-if="isImage || isVideo" class="uk-margin-medium-bottom">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.Resolution') }} </label>
                            <div>
                                <span class="uk-text-bold uk-text-left"> {{ getMediaResolutionLabel(mediaItem) }} </span>
                            </div>
                        </div>
                        <div class="uk-margin-medium-bottom">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.StorageType') }} </label>
                            <div>
                                <span class="uk-text-bold uk-text-left"> {{ isMediaFileInternal(mediaItem) ? 'Internal' : 'External' }} </span>
                            </div>
                        </div>
                        <div class="uk-margin-medium-bottom">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.Created') }} </label>
                            <div>
                                <span class="uk-text-bold uk-text-left"> {{ mediaItem.createdAt | date }}   </span>
                            </div>
                        </div>
                    </div>
                    <div v-if="!edit && hasLinkedContentOrAssets" class="uk-grid uk-width-1-3 uk-padding-remove">
                        <div class="uk-padding-remove uk-width-1-1" v-if="mediaItem.assets.length > 0">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.LinkedAssets') }} </label>
                            <ul class="uk-padding-remove uk-margin-remove uk-margin-small-left" v-for="(asset,i) in mediaItem.assets" :key="i">
                                <router-link type="button" :to="assetRedirection(asset)" class="uk-link uk-float-left source-media name-overflow uk-width-1-1">{{asset.name}}</router-link>
                            </ul>
                        </div>
                        <div class="uk-padding-remove uk-width-1-1" v-if="mediaItem.linkedContent.length > 0">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.LinkedContent') }} </label>
                            <ul class="uk-padding-remove uk-margin-remove uk-margin-small-left" v-for="(content,i) in mediaItem.linkedContent" :key="i"> 
                                <router-link type="button" :to="{ name: 'ContentDetails', params: { id: orgId, propertyId: mediaItem.linkedContent[i].collection.property.id, contentId: mediaItem.linkedContent[i].id } }" class="uk-link uk-float-left source-media name-overflow uk-width-1-1">{{content.primaryLanguage.title}}</router-link>
                            </ul>
                        </div>
                    </div>
                    <div v-else-if="!edit" class="uk-width-1-3"> 
                        <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.LinkedContentOrAssets') }} </label>
                        <p class="uk-text-bold uk-margin-remove"> {{ $t('Pages.MediaLibrary.NoMediaLinked') }} </p>
                    </div>
                </div>
                <div v-if="(isMediaProbeInProgress( probeMedia ) || isReprobeInProgress ) && checkCreatedDate( probeMedia )" class="uk-text-left uk-margin-small"> {{ $t( 'Pages.MediaLibrary.masterProbeInProgressRefresh' ) }}<span uk-spinner="ratio: 0.5" style="color: orange"> </span> </div>
                <div v-if="(isMediaProbeInProgress( probeMedia ) || isReprobeInProgress ) && !checkCreatedDate( probeMedia )" class="uk-text-left uk-margin-small"> {{ $t( 'Pages.MediaLibrary.masterProbeInProgress' ) }} <span uk-spinner="ratio: 0.5" style="color: orange"> </span> </div>
                <div v-if="isMediaTranscodeInProgress( transcodeMedia )" class="uk-text-left uk-margin-small"> {{ $t( 'Pages.MediaLibrary.transcodeInProgress' ) }} <span uk-spinner="ratio: 0.5" style="color: orange"> </span> </div>
                <div v-if="!edit" class="uk-margin-small-top">
                    <button v-if="isMediaTranscoded( mediaItem ) && isOrganizationTranscodeSupport( mediaItem.organization, getMediaTranscodeType( mediaItem ) )"  class="uk-button uk-button-primary uk-margin-small-right uk-align-left" @click="restartTranscode(mediaItem)"> {{ $t( 'Actions.Retranscode' ) }} </button>
                    <button v-if="isMediaProbeSupport( mediaItem ) && !isMaster" class="uk-button uk-button-primary uk-margin-small-right uk-align-left" @click="reprobeMedia(mediaItem)"> {{ $t( 'Actions.Reprobe' ) }} </button>
                    <button v-if="!deleting" class="uk-button uk-button-primary uk-margin-small-right uk-align-right" :disabled="isMaster" type="button" @click="deleteMedia" > {{ $t( 'Actions.Delete' ) }} </button>
                    <button v-else class="uk-button uk-button-primary uk-margin-small-right uk-align-right" disabled><div uk-spinner="ratio: 0.5"></div></button>

                </div>
                <div v-else class="uk-align-right">
                    <button class="uk-button uk-button-default uk-margin-small-right" @click="cancel"> {{ $t('Actions.Cancel') }} </button>
                    <button v-if="!saving" class="uk-button uk-button-primary uk-margin-small-right" :disabled="!isFormValid" @click="updateMedia"> {{ $t('Actions.Save') }} </button>
                    <button v-else class="uk-button uk-button-primary uk-margin-small-right" disabled><div uk-spinner="ratio: 0.5"></div></button>
                </div>
                <div>
                    <span class="uk-text-meta uk-align-right uk-margin-top" v-if="isMaster && !edit"> {{ $t( 'Pages.MediaLibrary.masterDeleteDisabled' ) }} </span>
                </div>
            </div>
        </div>
        <div id="delete-media-modal" uk-modal="bg-close: false; esc-close: false">
          <div class="uk-modal-dialog uk-modal-body">
              <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-text-warning" uk-icon="icon: info; ratio: 2" />
              <p class="uk-text-meta">{{ $t('DeleteModal.CannotDeleteMedia') }}</p>
          </div>
        </div>
    </div>
    <div v-else>
        <span class="uk-position-center" uk-spinner></span>
    </div>
</template>

<script>
import clipboardCopy from 'clipboard-copy';
import { getMediaSizeBytes } from '@/utils/mediaItem';
import { JobStatusType, MediaClass } from '@/utils/enums';
import Notification from '@/components/util/Notification';
import { isMediaProbeInProgress, isMediaTranscodeInProgress, isMediaProbeSupport, isMediaTranscoded, getMediaTranscodeType, isOrganizationTranscodeSupport } from '@/utils/transcode.js';
import IconicPlayer from '@/components/IconicPlayer';
import moment from 'moment';

const validUrlPattern = /^(http(s)?:\/\/){1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/; // eslint-disable-line

const MediaTypeNames = {
    CDNAsset: 'CDNAsset',
    ExternalAsset: 'ExternalAsset'
};
let pollTheServerForMediaStatus = null;
let pollTheServerForMediaTranscodeStatus = null;

export default {
    name: 'MediaItem',
    props: {
        mediaId: { type: String, required: true }
    },
    components: { IconicPlayer },
    data () {
        return {
            deleting: false,
            saving: false,
            mediaItem: null,
            copied: false,
            MediaClass,
            transcodeMedia: null,
            probeMedia: null,
            edit: false,
            title: this.mediaItem?.title,
            description: this.mediaItem?.description,
            externalURL: '',
            orgId: this.$route.params.id,
            isLoading: false,
            isReprobeInProgress:false
        }
    },
    mounted () {
        this.fetchMediaItem();
        this.checkForProbe();
        // this.checkForTranscode();
    },
    beforeDestroy () {
        clearInterval( pollTheServerForMediaStatus );
        clearInterval( pollTheServerForMediaTranscodeStatus );
    },
    computed: {
        url () {
            return this.mediaItem?.file?.url;
        },
        isDeletable () {
            return this.mediaItem.linkedContent.length === 0;
        },
        isMaster () {
            return this.mediaItem?.bundle?.masterVideo?.id === this.mediaItem.id;
        },
        isImage () {
            return this.mediaItem.mediaClass === MediaClass.IMAGE;
        },
        isVideo () {
            return this.mediaItem.mediaClass === MediaClass.VIDEO;
        },
        hasLinkedContentOrAssets () {
            return this.mediaItem?.assets.length > 0 || this.mediaItem?.linkedContent?.length > 0;
        },
        invalidName () {
            if ( this.title.length >= 0 && this.title.length < 1 ) {
                return false;
            }
            if ( !this.title.trim() && this.title.length > 0 )
                return false;
            return true;
        },
        validateUrl () {
            return validUrlPattern.test( this.externalURL );
        },
        isFormValid () {
            if ( !this.invalidName )
                return false;
            if ( !this.isMediaFileInternal(this.mediaItem) && !this.validateUrl )
                return false;
            return true;
        }
    },
    methods: {
        getMediaSizeBytes,
        isMediaProbeInProgress,
        isMediaTranscodeInProgress,
        isMediaProbeSupport,
        isMediaTranscoded,
        getMediaTranscodeType,
        isOrganizationTranscodeSupport,
        assetRedirection ( asset ) {
            if ( asset.owner.__typename === 'VodRef' )
                return { name: 'ContentDetails', params: { id: this.orgId, propertyId: asset.owner?.primaryFormat?.content?.collection?.property?.id, contentId: asset.owner?.primaryFormat?.content?.id } };
            else 
                return { name: 'AppEdit', params: { id: this.orgId, appId: asset.owner.id }, query: { tab: 'assets' } }
        },
        async fetchMediaItem () {
            this.isLoading = true
            this.mediaItem = await this.$store.dispatch('fetchMedia', { mediaId: this.mediaId } );
            this.isLoading = false
            this.title = this.mediaItem.title;
            this.description = this.mediaItem.description;
            this.externalURL = this.url;
        },
        isMediaFileInternal ( media ) {
            return media.file?.__typename === MediaTypeNames.CDNAsset;
        },
        async copy () {
            await clipboardCopy( this.mediaItem.file.url );
            this.copied = true;
        },
        getMediaResolutionLabel ( media ) {
            const { width, height } = media.resolution || {};

            return ( width || height ) ? `${width} × ${height}` : 'Not Available';
        },
        isTranscodeCompleted ( media ) {
            return media.transcodeStatus === JobStatusType.COMPLETED;
        },
        deleteMedia () {
        if ( this.mediaItem.linkedContent?.length || this.mediaItem.assets?.length  ) {
            window.UIkit.modal('#delete-media-modal').show();
        } else {
                window.UIkit.modal.confirm(this.$t( 'DeleteModal.ConfirmDeleteMedia', { name: this.mediaItem.title }), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }}).then( async () => {
                this.deleting = true
                const response = await this.$store.dispatch('deleteMedia', { mediaId: this.mediaItem.id });
                this.deleting = false
                if ( !response.errors ) {
                    Notification.showNotification(this.$t( 'DeleteModal.Removed'), this.$t( 'DeleteModal.HasBeenRemoved', { name: this.mediaItem.title }));
                    this.$router.go(-1);
                    this.$emit('refetch');
                } else {
                    Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), response?.errors[0]?.message,'error');
                }
                });
            }
        },
        async reprobeMedia ( media ) {
            window.UIkit.modal.confirm(this.$t( 'Pages.MediaLibrary.confirmReprobe'), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Reprobe')
            }}).then( async () => {
            await this.$store.dispatch('reprobeMedia',{ media: media.id });
            this.isReprobeInProgress = true;
            this.checkForProbe();
            },() => {
            });
        },
        async restartTranscode ( media ) {
            window.UIkit.modal.confirm(this.$t( 'Pages.MediaLibrary.confirmRestart')).then( async () => {
            await this.$store.dispatch('reTranscode',{ mediaId: media.id, orgId: this.orgId });
            this.checkForTranscode();
            Notification.showNotification('Retranscode', `Started retranscoding ${media.title}`);
            },() => {
            });
        },
        async checkForProbe () {
            pollTheServerForMediaStatus = setInterval( async () => {
                if ( !this.refetching ) {
                    this.refetching = true;
                    const media = await this.$store.dispatch('fetchMedia', { mediaId: this.mediaId } );
                    if ( media ) {
                        this.refetching = false;
                        this.probeMedia = media;
                        if ( !isMediaProbeInProgress( this.media ) && !isMediaTranscodeInProgress( this.media ) ){
                            this.isReprobeInProgress = false;
                            clearInterval( pollTheServerForMediaStatus );
                        }
                    }
                }
            }, 10000 );
        },
        async checkForTranscode () {
            pollTheServerForMediaTranscodeStatus = setInterval( async () => {
                if ( !this.reTranscode ) {
                    this.reTranscode = true;
                    const media = await this.$store.dispatch('fetchMedia', { mediaId: this.mediaId } );
                    if ( media ) {
                        this.reTranscode = false;
                        this.transcodeMedia = media;
                        if ( !isMediaTranscodeInProgress( this.media ) )
                            clearInterval( pollTheServerForMediaTranscodeStatus );
                    }
                }
            }, 60000 );
        },
        clicked () {
            this.edit = true;
        },
        async updateMedia () {
           this.saving = true
           const response = await this.$store.dispatch('updateMedia',{variables: { media: this.mediaItem.id, title: this.title, description: this.description, external: !this.isMediaFileInternal( this.mediaItem ) ? this.externalURL : null }})
           this.saving = false
           if( response ) {
                Notification.showNotification(this.$t('Actions.Updated'), this.$t('Pages.MediaLibrary.MediaUpdatedSuccessfully'));
                this.fetchMediaItem();
                this.edit = false;
           }
        },
        cancel () {
            this.edit = false;
            this.title = this.mediaItem.title;
            this.description = this.mediaItem.description;
            this.externalURL = this.url;
        },
        async checkCreatedDate( master ) {
             if(!master){
                return false;
            }
            const today = moment(master.createdAt);
            const dateToCheck = moment().subtract(1, 'd');
            return today.diff(dateToCheck, 'd') > 1;
        }
    }
}
</script>

<style lang="scss" scoped>
.iconic-player {
    width: 600px;
    height: 365px;
}

.name-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.media-header-text .uk-icon {
    line-height: normal;
}
</style>